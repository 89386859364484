import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Functions
import { DetermineLinkColor } from "../utils/determine-link-color";

// Components
import { TableSortMobile } from "../projects/table-sort-mobile";
import { TableFilterMobile } from "../projects/table-filter-mobile";

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  & ol {
    width: 100%;

    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & li {
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 0.02em;

      font-family: "neue-haas-grotesk-text", sans-serif;
      font-weight: 700;

      padding: 0 10px 0 0;

      &:last-of-type {
        padding: 0;
      }

      & a {
        color: ${props => props.linkColor};
        &:hover {
          color: #000;
        }
      }

      & a.current,
      & a.active {
        color: #000;
      }

      & .pup-hover {
        // &:hover {
        //   color: #e5e5e5;
        // }
      }
    }

    & .col-left {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      margin-right: auto;

      & .spacer {
        padding: 0 20px;
      }

      & li:last-of-type {
        margin: 0 auto 0 0;
      }
    }

    & .col-right {
      margin-left: auto;
      flex: 1;
      display: flex;

      & ol {
        justify-content: flex-end;
      }
    }
  }
`;

const Menu = styled.div`
  padding: 1px 10px 0 10px;

  width: 100%;

  border-bottom: 1px solid #555;
`;

const SubMenu = styled.div`
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 1px 10px 0 10px;

  width: 100%;

  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  font-family: "neue-haas-grotesk-text", sans-serif;
  font-weight: 700;

  @media (max-width: 768px) {
    display: ${props => props.display};
  }
`;

const Filters = styled.div`
  & button {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.02em;

    cursor: pointer;

    &:hover {
      color: ${props => props.linkColor};
    }
  }
`;

export const MobileMenu = ({ pageColor, pageType, links }) => {
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const menuLinks = links.map((link, index) => (
    <li key={`menu_link_${index}`}>
      <Link to={`${link.link}`} activeClassName={`current`} partiallyActive>
        {link.name}
      </Link>
    </li>
  ));

  return (
    <Nav
      role="navigation"
      aria-label="main navigation"
      linkColor={DetermineLinkColor(pageColor)}
    >
      <Menu>
        <ol>
          <li className="col-left">
            <ol>
              <li>
                <Link to={`/`} className="active pup-hover">
                  P<span className="spacer">U</span>P
                </Link>
              </li>
            </ol>
          </li>

          <li className="col-right">
            <ol>
              <li>
                <Link
                  to={`/projects`}
                  activeClassName={`current`}
                  className={
                    pageType === `project` || pageType === `home`
                      ? `current`
                      : ``
                  }
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  to={`/profile`}
                  activeClassName={`current`}
                  className={
                    pageType === `practice` || pageType === `home`
                      ? `current`
                      : ``
                  }
                >
                  Practice
                </Link>
              </li>
            </ol>
          </li>
        </ol>
      </Menu>
      <SubMenu display={pageType === "practice" ? `flex` : `none`}>
        <ol className="menu-links">{menuLinks}</ol>
      </SubMenu>

      <SubMenu display={pageType === "projects" ? `flex` : `none`}>
        <Filters>
          <button onClick={() => setCategoriesOpen(!categoriesOpen)}>
            Categories {categoriesOpen === true ? `–` : `+`}
          </button>
          <TableFilterMobile
            display={categoriesOpen === true ? `block` : `none`}
            setCategoriesOpen={setCategoriesOpen}
          />
        </Filters>
        <TableSortMobile />
      </SubMenu>
    </Nav>
  );
};
