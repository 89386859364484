import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import {
  ProjectsActiveFilterProvider,
  ProjectsActiveFilterConsumer,
} from "../context/projects-active-filter";

const FilterContainer = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  flex: 1;
  text-align: center;

  &:hover {
    & li button,
    & li.active-link button {
      color: ${props => props.linkColor};
    }

    & li.active-link:hover button {
      color: #000;
    }
  }

  & li {
    padding: 0 9px;

    font-size: 12px;
    line-height: 19px;
    text-transform: uppercase;
    letter-spacing: 0.02em;

    font-family: "neue-haas-grotesk-text", sans-serif;
    font-weight: 700;

    & a {
      color: ${props => props.linkColor};
      &:hover {
        color: #000;
      }
    }

    & a.current,
    & a.active {
      color: #000;
    }

    & button {
      cursor: pointer;
      text-transform: uppercase;
      line-height: 19px;
      color: ${props => props.linkColor};

      &:hover {
        color: #000;
      }
    }
  }

  & li.active-link {
    & button {
      color: #000;
    }
  }

  @media (max-width: 1099px) {
    padding: 0 0 30px 0;
  }
`;

const TableFilterContent = ({
  filters,
  linkColor,
  setProjectsActiveFilter,
  projectsActiveFilter,
}) => {
  const allFilters = filters.map(filter => (
    <li
      key={`filter_${filter}`}
      className={projectsActiveFilter === filter ? "active-link" : ""}
    >
      <button onClick={() => setProjectsActiveFilter(filter)}>{filter}</button>
    </li>
  ));

  return (
    <FilterContainer linkColor={linkColor}>
      <li className={projectsActiveFilter === "All" ? "active-link" : ""}>
        <button onClick={() => setProjectsActiveFilter("All")}>All</button>
      </li>

      {allFilters}
    </FilterContainer>
  );
};

export const TableFilterDesktop = ({ linkColor }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicProject {
        edges {
          node {
            tags
          }
        }
      }
    }
  `);

  const filters = Array.from(
    new Set(
      data.allPrismicProject.edges.map(project => project.node.tags).flat()
    )
  ).sort();

  return (
    <ProjectsActiveFilterConsumer>
      {({ setProjectsActiveFilter, projectsActiveFilter }) => (
        <TableFilterContent
          filters={filters}
          linkColor={linkColor}
          setProjectsActiveFilter={setProjectsActiveFilter}
          projectsActiveFilter={projectsActiveFilter}
        />
      )}
    </ProjectsActiveFilterConsumer>
  );
};
