import React from "react";
import { Helmet } from "react-helmet";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import "array-flat-polyfill";
import { useFavicon } from "react-use";

// Components
import GlobalStyles from "../styles/globalStyles";
import App from "./app";

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const Layout = ({ children }) => {
  useFavicon("https://www.puparchitects.com/favicon/favicon.png");

  return (
    <Location>
      {({ location }) => {
        return (
          <>
            <Helmet>
              <meta
                name="viewport"
                content="width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1"
              />
              <link
                rel="stylesheet"
                href="https://use.typekit.net/khg5ose.css"
              />
            </Helmet>
            <Container location={location.pathname}>
              <Normalize />
              <GlobalStyles />
              <App location={location} children={children} />
            </Container>
          </>
        );
      }}
    </Location>
  );
};

export default Layout;
