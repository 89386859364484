import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Media from "react-media";

// Functions
import { DeterminePageColor } from "../utils/determine-page-color";
import { DetermineLinkColor } from "../utils/determine-link-color";

// Components
import { TableFilterDesktop } from "../projects/table-filter-desktop";

const Nav = styled.nav`
  padding: 0 20px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : `#fff`};

  & .flex-10 {
    flex: 0 0 10px;
  }

  & .flex-1 {
    flex: 1;
    text-align: center;
  }

  & ol {
    width: 100%;

    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    & li {
      font-size: 12px;
      line-height: 19px;
      text-transform: uppercase;
      letter-spacing: 0.02em;

      font-family: "neue-haas-grotesk-text", sans-serif;
      font-weight: 700;

      &:not(.homepage-menu-item) {
        & a {
          color: ${props => props.linkColor};
          &:hover {
            color: #000;
          }
        }
      }

      &.homepage-menu-item {
        & a {
          &:hover {
            color: #e5e5e5;
          }
        }
      }

      & a.current,
      & a.active {
        color: #000;
      }

      & button {
        cursor: pointer;
        text-transform: uppercase;
        line-height: 19px;
      }
    }

    & .col-left {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      margin-right: auto;
    }

    & .col-centre {
      flex: 2;
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }

    & .col-right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }

    & .menu-links {
      & li:nth-of-type(2) {
        padding: 0 15px;
      }
    }
  }
`;

export const DesktopMenu = ({
  pageColor,
  pageType,
  links,
  windowWidth,
  location,
}) => {
  const menuLinks = links.map((link, index) => (
    <li key={`menu_link_${index}`}>
      <Link to={`${link.link}`} activeClassName={`current`} partiallyActive>
        {link.name}
      </Link>
    </li>
  ));

  return (
    <Nav
      role="navigation"
      aria-label="main navigation"
      linkColor={DetermineLinkColor(pageColor)}
      backgroundColor={DeterminePageColor(pageColor)}
    >
      <ol>
        <li className="flex-10">
          <Link to={`/`} className="active pup-hover">
            P
          </Link>
        </li>

        <Media
          queries={{ medium: "(max-width: 1099px)" }}
          defaultMatches={{ medium: windowWidth === 1099 }}
          render={() => (
            <>
              {pageType !== "single-project" && (
                <li
                  className={`flex-1 ${
                    location.pathname === "/" ? `homepage-menu-item` : ``
                  }`}
                >
                  <Link to={`/projects`} activeClassName={`current`}>
                    Projects
                  </Link>
                </li>
              )}
            </>
          )}
        />

        <Media
          queries={{ medium: "(min-width: 1100px)" }}
          defaultMatches={{ medium: windowWidth === 1100 }}
          render={() => (
            <>
              {pageType !== "projects" && pageType !== "single-project" && (
                <li
                  className={`flex-1 ${
                    location.pathname === "/" ? `homepage-menu-item` : ``
                  }`}
                >
                  <Link to={`/projects`} activeClassName={`current`}>
                    Projects
                  </Link>
                </li>
              )}
            </>
          )}
        />

        {pageType === "single-project" && (
          <li className="flex-1">
            <Link to={`/projects`} activeClassName={`current`}>
              Back To Projects
            </Link>
          </li>
        )}

        <Media
          queries={{ medium: "(min-width: 1100px)" }}
          defaultMatches={{ medium: windowWidth === 1100 }}
          render={() => (
            <>
              {pageType === "projects" && (
                <TableFilterDesktop linkColor={DetermineLinkColor(pageColor)} />
              )}
            </>
          )}
        />

        <li className="flex-10 center">
          <Link to={`/`} className="active pup-hover">
            U
          </Link>
        </li>

        {pageType !== "practice" && (
          <li
            className={`flex-1 ${
              location.pathname === "/" ? `homepage-menu-item` : ``
            }`}
          >
            <Link to={`/profile`}>Practice</Link>
          </li>
        )}

        {pageType === "practice" && (
          <li className="flex-1">
            <ol className="menu-links">{menuLinks}</ol>
          </li>
        )}

        <li className="flex-10 right">
          <Link to={`/`} className="active pup-hover">
            P
          </Link>
        </li>
      </ol>
    </Nav>
  );
};
