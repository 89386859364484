import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Context
import WindowWidthContext from "../context/window-width";
import { PageColorProvider, PageColorConsumer } from "../context/page-color";
import { PageTypeProvider, PageTypeConsumer } from "../context/page-type";
import { ProjectsActiveFilterProvider } from "../context/projects-active-filter";
import { ProjectsTableContextProviderComponent } from "../context/projects-table-header-filter";

// SEO
import { DefaultSEO } from "./default-seo";

// Components
import Header from "./header";

const Main = styled.main`
  flex: 1;
`;

const App = ({ location, data, children }) => {
  const [displayApp, setDisplayApp] = useState(false);

  // Window Width
  // const [updatedWindowWidth, setWindowWidth] = useState(999);
  const [updatedWindowWidth, setWindowWidth] = useState(768);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayApp(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <WindowWidthContext.Provider value={updatedWindowWidth}>
      <ProjectsTableContextProviderComponent>
        <ProjectsActiveFilterProvider>
          <>
            <PageColorProvider>
              <PageColorConsumer>
                {({ pageColor, setPageColor }) => (
                  <PageTypeProvider>
                    <PageTypeConsumer>
                      {({ pageType }) => (
                        <>
                          <DefaultSEO />
                          <Header
                            location={location}
                            pageType={pageType}
                            pageColor={pageColor}
                          />
                          {displayApp === true && <Main>{children}</Main>}
                        </>
                      )}
                    </PageTypeConsumer>
                  </PageTypeProvider>
                )}
              </PageColorConsumer>
            </PageColorProvider>
          </>
        </ProjectsActiveFilterProvider>
      </ProjectsTableContextProviderComponent>
    </WindowWidthContext.Provider>
  );
};

export default App;
