export function DetermineLinkColor(color) {
  let linkColor;
  if (color === "#FFFFFF" || color === "White") {
    linkColor = "#E5E5E5";
  }

  if (color === "#FFF3F4" || color === "Pink") {
    linkColor = "#F2CECF";
  }

  if (color === "#F6FDEE" || color === "Green") {
    linkColor = "#D9F1D8";
  }

  if (color === "#F3FCFF" || color === "Blue") {
    linkColor = "#DAE9F3";
  }

  if (color === "#FFFBE4" || color === "Yellow") {
    linkColor = "#F9E49A";
  }

  return linkColor;
}
