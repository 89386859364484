import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

// Context
import { ProjectsActiveFilterConsumer } from "../context/projects-active-filter";

const FilterContainer = styled.ul`
  display: ${props => props.display};

  & li {
    padding: 0;

    & button {
      color: #e5e5e5;
    }
  }

  & li.active-link {
    & button {
      color: #000;
    }
  }
`;

const TableFilterContentMobile = ({
  filters,
  linkColor,
  setProjectsActiveFilter,
  projectsActiveFilter,
  display,
  setCategoriesOpen,
}) => {
  useEffect(() => {
    if (display === `block`) {
      setCategoriesOpen(false);
    }
  }, [projectsActiveFilter]);

  const allFilters = filters.map(filter => (
    <li
      key={`filter_${filter}`}
      className={projectsActiveFilter === filter ? "active-link" : ""}
    >
      <button onClick={() => setProjectsActiveFilter(filter)}>{filter}</button>
    </li>
  ));

  return (
    <FilterContainer linkColor={linkColor} display={display}>
      <li className={projectsActiveFilter === "All" ? "active-link" : ""}>
        <button onClick={() => setProjectsActiveFilter("All")}>All</button>
      </li>

      {allFilters}
    </FilterContainer>
  );
};

export const TableFilterMobile = ({
  linkColor,
  display,
  setCategoriesOpen,
}) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicProject {
        edges {
          node {
            tags
          }
        }
      }
    }
  `);

  const filters = Array.from(
    new Set(
      data.allPrismicProject.edges.map(project => project.node.tags).flat()
    )
  ).sort();

  return (
    <ProjectsActiveFilterConsumer>
      {({ setProjectsActiveFilter, projectsActiveFilter }) => (
        <TableFilterContentMobile
          filters={filters}
          linkColor={linkColor}
          setProjectsActiveFilter={setProjectsActiveFilter}
          projectsActiveFilter={projectsActiveFilter}
          display={display}
          setCategoriesOpen={setCategoriesOpen}
        />
      )}
    </ProjectsActiveFilterConsumer>
  );
};
