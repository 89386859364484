export function DeterminePageColor(color) {
  let pageColor;
  if (color === "#FFFFFF" || color === "White") {
    pageColor = "#FFFFFF";
  }

  if (color === "#FFF3F4" || color === "Pink") {
    pageColor = "#FFF3F4";
  }

  if (color === "#F6FDEE" || color === "Green") {
    pageColor = "#F6FDEE";
  }

  if (color === "#F3FCFF" || color === "Blue") {
    pageColor = "#F3FCFF";
  }

  if (color === "#FFFBE4" || color === "Yellow") {
    pageColor = "#FFFBE4";
  }

  return pageColor;
}
