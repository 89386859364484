import React from "react";

export const windowWidth = {
  // windowWidth: 999,
  windowWidth: 768,
  updateWindowWidth: () => {},
};

const WindowWidthContext = React.createContext(windowWidth);

export default WindowWidthContext;
