import React from "react";
import styled from "styled-components";

// Context
import ContextConsumer from "../context/projects-table-header-filter";

const SortContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  padding: 0;

  &:first-of-type {
    margin: 0 43px 0 0;
  }

  &:last-of-type {
    margin: 0 18px 0 0;
  }
`;

const TableSortButton = styled.button`
  cursor: pointer;

  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

export const TableSortMobile = () => (
  <ContextConsumer>
    {({ activeTableFilter, set }) => (
      <SortContainer>
        <Col className="nhg-small heading">
          <TableSortButton
            onClick={() => set(activeTableFilter === "year" ? "-year" : "year")}
          >
            Year
          </TableSortButton>
        </Col>
        <Col className="nhg-small heading">
          <TableSortButton
            onClick={() =>
              set(activeTableFilter === "client" ? "-client" : "client")
            }
          >
            Client
          </TableSortButton>
        </Col>
      </SortContainer>
    )}
  </ContextConsumer>
);
