import React from "react";

const defaultContextValue = {
  activeTableFilter: "-year",
  set: () => {},
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

class ProjectsTableContextProviderComponent extends React.Component {
  constructor() {
    super();

    this.setTableFilter = this.setTableFilter.bind(this);
    this.state = {
      ...defaultContextValue,
      set: this.setTableFilter,
    };
  }

  setTableFilter(newData) {
    this.setState(state => ({
      activeTableFilter: newData,
    }));
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export { Consumer as default, ProjectsTableContextProviderComponent };
