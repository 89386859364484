import React, { useContext, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Media from "react-media";

// Context
import WindowWidthContext from "../context/window-width";

// Functions
import { DeterminePageColor } from "../utils/determine-page-color";

// Components
import { MobileMenu } from "../navigation/mobile-menu";
import { DesktopMenu } from "../navigation/desktop-menu";

const HeaderContainer = styled.header`
  position: sticky;

  top: 0;
  left: 0;
  right: 0;

  width: 100%;

  z-index: 200;

  border-bottom: 1px solid #555;
  background-color: ${props => props.pageColor};

  // @media (max-width: 999px) {
  //   border-bottom: 0;
  // }

  @media (max-width: 768px) {
    border-bottom: 0;
  }
`;

export const Header = ({ pageType, pageColor, location }) => {
  const windowWidth = useContext(WindowWidthContext);

  useEffect(() => {
    document.body.style.backgroundColor = DeterminePageColor(pageColor);
  }, [location, pageColor]);

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  return (
    <HeaderContainer pageColor={DeterminePageColor(pageColor)}>
      <Media
        queries={{ medium: "(max-width: 768px)" }}
        defaultMatches={{ medium: windowWidth === 768 }}
        render={() => (
          <MobileMenu
            pageColor={pageColor}
            pageType={pageType}
            links={data.site.siteMetadata.menuLinks}
            windowWidth={windowWidth}
            location={location}
          />
        )}
      />

      <Media
        queries={{ medium: "(min-width: 769px)" }}
        defaultMatches={{ medium: windowWidth === 769 }}
        render={() => (
          <DesktopMenu
            pageColor={pageColor}
            pageType={pageType}
            links={data.site.siteMetadata.menuLinks}
            windowWidth={windowWidth}
            location={location}
          />
        )}
      />
    </HeaderContainer>
  );
};

export default Header;
