import React, { createContext } from "react";

const PageColor = createContext({
  pageColor: "White",
  setPageColor: () => {},
});

export class PageColorProvider extends React.Component {
  setPageColor = newPageColor => {
    this.setState({ pageColor: newPageColor });
  };

  state = {
    pageColor: "White",
    setPageColor: this.setPageColor,
  };

  render() {
    return (
      <PageColor.Provider value={this.state}>
        {this.props.children}
      </PageColor.Provider>
    );
  }
}

export const PageColorConsumer = PageColor.Consumer;
